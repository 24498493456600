<template>
  <div class="accordion body" role="tablist">
    <div class="accordion-item">
      <div class="accordion-header" role="tab">
        LabPacs
        <ChevronDown class="icon chevron" v-b-toggle.accordion-labpacs-config />
      </div>
      <b-collapse id="accordion-labpacs-config" visible accordion="labpacs-config-accordion" role="tabpanel"
                  class="accordion-body">
        <div>
          <b-form-group>
            <label for="ownerNumber">Owner Number</label>
            <b-form-input
              type="text"
              id="ownerNumber"
              placeholder="ex.: 1234567890"
              v-model="ownerNumber"
              class="form-control"
            />

          </b-form-group>
          <b-form-group class="accordion-body__actions">
            <b-button variant="primary" @click="saveOwnerNumber" v-if="!labpacsClinicsId">Salvar</b-button>
            <b-button variant="primary" @click="updateOwnerNumber" v-else>Atualizar</b-button>

          </b-form-group>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>

import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'LabpacsConfig',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  data() {
    return {
      labpacsClinicsId: null,
      ownerNumber: '',
      clinic: getCurrentClinic()
    }
  },
  mounted() {
    const isLoading = this.$loading.show()
    this.getOwnerNumber()
    isLoading.hide()
  },
  methods: {
    async saveOwnerNumber() {
      const isLoading = this.$loading.show()
      try {
        const ownerId = this.ownerNumber
        const clinicId = this.clinic.id

        const data = {
          ownerId,
          clinicId
        }
        const response = await this.api.createLabpacsClinicsConfig(data)

        if (response) {
          this.$toast.success('Owner Id salvo com sucesso')
        }
      } catch (error) {
        console.error(error)
        this.$toast.error('Erro ao cadastrar Owner Id. Tente novamente mais tarde ou entre em contato com o suporte.')
        throw error
      } finally {
        isLoading.hide()
      }
    },

    async getOwnerNumber() {
      const isLoading = this.$loading.show()
      try {
        const clinicId = this.clinic.id
        const response = await this.api.getLabpacsClinicsConfigByClinic(clinicId)
        if (response.data) {
          this.ownerNumber = response.data.owner_id
          this.labpacsClinicsId = response.data.id
        }
      } catch (error) {
        console.error(error)
        this.$toast.error('Erro ao obter Owner Id. Tente novamente mais tarde ou entre em contato com o suporte.')
        throw error
      } finally {
        isLoading.hide()
      }
    },

    async updateOwnerNumber() {
      const isLoading = this.$loading.show()
      try {
        const ownerId = this.ownerNumber
        const clinicId = this.clinic.id

        const data = {
          ownerId,
          clinicId
        }

        const response = await this.api.updateLabpacsClinicsConfig(this.labpacsClinicsId, data)

        if (response) {
          this.$toast.success('Owner Id atualizado com sucesso')
        }
      } catch (error) {
        console.error(error)
        this.$toast.error('Erro ao atualizar Owner Id. Tente novamente mais tarde ou entre em contato com o suporte.')
        throw error
      } finally {
        isLoading.hide()
      }

    }
  }
}
</script>

<style scoped>
.body {
  width: 70%;

  .accordion-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;

    background: #FFFFFF;
    /* elevation/1 */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 0px 0px;

    .accordion-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      padding: 5px 0;


      /* Inside auto layout */
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      cursor: pointer;
    }

    .accordion-body {
      display: flex;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        flex: 1;

        .accordion-body__actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
  }
}

.icon {
  width: 27px;
  height: 27px;
}

.chevron {
  transition: all 0.5s;
  cursor: pointer;

  &.not-collapsed {
    animation: rotateUp 0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  &.collapsed {
    animation: rotateDown 0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    transform: rotate(180deg);
  }

  &.chevron:focus {
    outline: none !important;
  }
}

</style>